import Vue from 'vue';
import VueI18n from 'vue-i18n';

// import { ApiService } from '@/services/api.service';
import App from './App.vue';
import router from './router';
import store from './store';

import messages from './json/translations.json';

Vue.config.productionTip = false;

Vue.use(VueI18n);

// ApiService.on(401, () => {
//   store.commit('setJWT', '');
//   router.push('/');
// });

router.beforeEach((to, from, next) => {
  const routerAuthCheck = store.state.jwt;
  const expTime = store.state.jwtExp;
  const time = Date.now() / 1000;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const { redirectWithAuth } = to.meta;

  if (requiresAuth && routerAuthCheck) {
    if (expTime < time) {
      store.commit('setJWT', '');
      next('/');
    } else {
      next();
    }
  } else if (requiresAuth && !routerAuthCheck) {
    next(`/?next=${to.fullPath}`);
  } else if (redirectWithAuth && routerAuthCheck) {
    next('/home');
  } else if (!from.query.next || to.query.next) next();
  else next(`${to.path}?next=${from.query.next}`);
});

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
