<template>
  <nav aria-label="primary" class="g-side-nav overlay">
    <!-- <AppUserWelcome v-if="$mq !== 'lg'" /> -->
    <div class="nav">
      <ul class="main-nav">
        <!-- @slot Renders inside an ul so expects list of </li>'s -->
        <slot name="mainNav">
          <div class="list-holder">
            <li>
              <router-link to="/home" exact class="route" @click.native="navigate">
                <HomeInactive class="icon inactive" />
                <HomeActive class="icon active" />
                <span>{{ $t('navigation.home') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/stamps" class="route" @click.native="navigate">
                <StampsInactive class="icon inactive" />
                <StampsActive class="icon active" />
                <span>{{ $t('navigation.easterEggs') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/scanner" class="route" @click.native="navigate">
                <ScannerInactive class="icon inactive" />
                <ScannerActive class="icon active" />
                <span>{{ $t('navigation.scanner') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/leaderboard" class="route" @click.native="navigate">
                <LeaderboardInactive class="icon inactive" />
                <LeaderboardActive class="icon active" />
                <span>{{ $t('navigation.leaderboard') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/terms" class="route" @click.native="navigate">
                <LegalInactive class="icon inactive" />
                <LegalActive class="icon active" />
                <span>{{ $t('navigation.legal') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/settings" class="route" @click.native="navigate">
                <SettingsInactive class="icon inactive" />
                <SettingsActive class="icon active" />
                <span>{{ $t('navigation.settings') }}</span>
              </router-link>
            </li>
          </div>
          <li>
            <a @click="logout" class="logout">{{ $t('navigation.logout') }}</a>
          </li>
        </slot>
      </ul>
    </div>
  </nav>
</template>

<script>
import HomeActive from '@/assets/icons/home_active.svg';
import HomeInactive from '@/assets/icons/home_inactive.svg';
import ScannerActive from '@/assets/icons/scanner_active.svg';
import ScannerInactive from '@/assets/icons/scanner_inactive.svg';
import StampsActive from '@/assets/icons/stamps_active.svg';
import StampsInactive from '@/assets/icons/stamps_inactive_v2.svg';
import LeaderboardActive from '@/assets/icons/leaderboard_active.svg';
import LeaderboardInactive from '@/assets/icons/leaderboard_inactive.svg';
import SettingsInactive from '@/assets/icons/settings_inactive.svg';
import SettingsActive from '@/assets/icons/settings_active.svg';
import LegalActive from '@/assets/icons/legal_active.svg';
import LegalInactive from '@/assets/icons/legal_inactive.svg';
import { ApiService } from '@/services/api.service';
/**
 * @version 0.0.1
 */
export default {
  name: 'VWSideNav',
  components: {
    HomeActive,
    HomeInactive,
    StampsInactive,
    StampsActive,
    ScannerInactive,
    ScannerActive,
    LeaderboardInactive,
    LeaderboardActive,
    SettingsInactive,
    SettingsActive,
    LegalActive,
    LegalInactive,
  },
  data() {
    return {};
  },
  methods: {
    logout() {
      ApiService.post('/user/logout').then(() => {
        this.$store.commit('setJWT', '');
        this.$store.commit('setEmail', '');
        this.$store.commit('setTrivia', []);
        this.$store.commit('setNumTriviaQuestions', -1);
        this.$store.commit('unsetCongratsAsCamera');
        this.$router.push('/');
        this.$emit('close');
      });
    },

    navigate() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.g-side-nav {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 6;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  font-family: $head-font-stack;
  font-size: 10px;
  color: black;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  &.overlay {
    background-color: rgba(0, 0, 0, 0.75);
  }
  // @include bp-md-tablet {
  //   width: 382px;
  // }
  .nav {
    width: 80%;
    height: 100%;
    background-color: #243a53;
    // background-image: url(../assets/images/menu_bg@2x.png);
    // background-position: center;
    // background-size: cover;
  }

  .main-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: auto;
    padding: 0;
    padding-bottom: 27px;
    margin: 0;
    font-size: 1.8em;
    font-weight: 300;
    color: $primary-white;
    list-style-type: none;
    .list-holder {
      width: 87%;
      margin-top: 2em;
      margin-left: 1em;
    }
    li {
      flex: 1 1 20%;
      width: 100%;
    }

    .route {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 10px;
      margin-top: 20px;
      line-height: 1.2;
      color: inherit;
      text-align: left;
      text-decoration: none;
      letter-spacing: -0.15px;
      border-radius: 0.3em;

      &:hover {
        background-color: #ffffff26;
      }
      &.router-link-active {
        // font-weight: bold;
        background-color: #ffffff26;
        .icon {
          &.inactive {
            display: none;
            opacity: 0;
          }
          &.active {
            display: inline-block;
            opacity: 1;
          }
          path {
            stroke-width: 0;
          }
        }
      }
    }

    .icon {
      display: inline-block;
      width: 30px;
      height: 24px;
      margin-right: 15px;
      vertical-align: middle;
      path,
      rect {
        fill: currentColor;
      }
      &.active {
        display: none;
      }
    }
  }
}
.logout {
  position: fixed;
  right: 0;
  bottom: 1.5em;
  left: 1em;
  width: min-content;
  padding: 10px;
  font-size: 14px;
  font-weight: 700;
  color: $primary-white;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 0.3em;
  &:hover {
    background-color: #ffffff26;
  }
}
</style>
