<template>
  <div class="navbar">
    <HamMenu
      v-if="menu && !showNav"
      class="menu"
      @click="showNav = !showNav"
      :aria-label="$t('navigation.openMenu')"
      role="button"
    />
    <XIcon
      v-else-if="menu"
      class="menu"
      @click="showNav = !showNav"
      :aria-label="$t('navigation.closeMenu')"
      role="button"
    />
    <PassportSideNav v-if="showNav" @close="showNav = false" />
    <BroncoLogo class="main-logo" />
    <!-- <div class="points-area">
      <span class="points__bold">{{ points }}</span>
      <span class="points">&nbsp;points</span>
    </div> -->
  </div>
</template>

<script>
import PassportSideNav from '@/components/PassportSideNav.vue';
import BroncoLogo from '@/assets/ford/Horizontal_Logo.svg';
import XIcon from '@/assets/icons/x_icon.svg';
import HamMenu from '@/assets/ford/Hamburger_Menu.svg';
// import { ApiService } from '@/services/api.service';

export default {
  name: 'Login',
  components: {
    PassportSideNav,
    HamMenu,
    XIcon,
    BroncoLogo,
  },
  props: {
    menu: Boolean,
  },
  data() {
    return {
      showNav: false,
      isHomeActive: false,
      isScannerActive: false,
      isStampActive: false,
      isLeaderboardActive: false,
      userId: this.$store.state.uuid,
      points: this.$store.state.points,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-logo {
  height: 39px;
}
.points-area {
  position: absolute;
  right: 0;
}
.points {
  margin-right: 1em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  &__bold {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
}
.menu {
  position: fixed;
  top: 26px;
  left: 0;
  z-index: 9;
  width: 2em;
  height: 1.2em;
  margin-left: 1em;
  cursor: pointer;
}
</style>
