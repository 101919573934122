<template>
  <div id="app" :lang="locale" translate="no">
    <TopNav :menu="showMenu" v-if="showTopNav" />
    <router-view class="router-view" />
    <BottomNav v-if="showBottomNav" />
  </div>
</template>

<script>
import TopNav from '@/components/TopNav.vue';
import BottomNav from '@/components/BottomNav.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'App',
  components: { TopNav, BottomNav },
  data() {
    return {};
  },
  computed: {
    showTopNav() {
      return this.$route.path !== '/' && this.$route.path !== '/verify' && this.$route.path !== '/reset-password';
    },
    showMenu() {
      return this.$route.path !== '/login' && this.$route.path !== '/register';
    },
    showBottomNav() {
      return this.showTopNav && this.showMenu && !this.$route.params.hideBottomNav;
    },
    locale() {
      return this.$store.state.locale;
    },
  },
  mounted() {
    ApiService.setAuth(this.$store.state.jwt);
    this.updateBG();
    if (!this.$store.state.preapproved) {
      ApiService.get('/config').then((res) => {
        if (!res.data.sitePublic && this.$route.name !== 'locked') {
          this.$router.replace(`/locked/?from=${this.$route.fullPath}`);
        } else if (res.data.sitePublic && this.$route.name === 'locked') {
          const { from } = this.$route.query;
          this.$router.replace(from || '/');
        }
      });
    }
  },
  watch: {
    $route() {
      this.updateBG();
    },
    locale: {
      handler() {
        this.$i18n.locale = this.locale;
      },
      immediate: true,
    },
  },
  methods: {
    redirect() {
      ApiService.on(401, () => {
        this.$router.push('/login');
      });
    },
    updateBG() {
      if (this.$route.name === 'main') {
        document.body.classList.add('blue-bg');
      } else {
        document.body.classList.remove('blue-bg');
      }
    },
  },
};
</script>

<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type

@import url(assets/ford/FordF1/stylesheet.css);

#app {
  font-family: $head-font-stack;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #222;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  margin: 0;
  background-color: #f1d9b2;
  background-image: image-set(url(assets/ford/Beige_BG.png) 1x, url(assets/ford/Beige_BG@2x.png) 2x);
  background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center top;
  background-size: cover;
  &.blue-bg {
    background-color: #243a53;
    background-image: image-set(url(assets/ford/Blue_BG.png) 1x, url(assets/ford/Blue_BG@2x.png) 2x);
  }
}
h1 {
  margin-top: 0;
  margin-bottom: 41px;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h2 {
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.navbar {
  // position: fixed;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  padding: 15px;
  // background-image: url(assets/images/bg_nav@2x.png);
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  // box-shadow: 0 2px 5px black;
  // @include bp-md-tablet {
  //   background-image: url(assets/images/bg_desktop@2x.png);
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  // }
}
.router-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 90px 30px;
  margin-top: 69px;
}
.bottom-navbar {
  gap: 25px;
  // background-image: url(assets/images/bg_nav.png);
  background-color: #243a53;
}

.logo {
  margin-bottom: 8em;
}
</style>
