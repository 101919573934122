import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    meta: { redirectWithAuth: true },
    component: () => import(/* webpackChunkName: "main" */ '@/views/Main.vue'),
  },
  {
    path: '/login',
    name: 'login',
    meta: { redirectWithAuth: true },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
  },
  {
    path: '/register',
    name: 'register',
    meta: { redirectWithAuth: true },
    component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "tutorial" */ '@/views/Tutorial.vue'),
  },
  {
    path: '/home',
    name: 'home',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "terms" */ '@/views/Legal.vue'),
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "leaderboard" */ '@/views/Leaderboard.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
  },
  {
    path: '/stamps',
    name: 'stamps',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "stamps" */ '@/views/Stamps.vue'),
  },
  {
    path: '/scanner',
    name: 'scanner',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "scanner" */ '@/views/Scanner.vue'),
  },
  // {
  //   path: '/hint/:title/:text',
  //   name: 'hint',
  //   meta: { requiresAuth: true },
  //   props: true,
  //   component: () => import(/* webpackChunkName: "hint" */ '@/components/Hint.vue'),
  // },
  {
    path: '/trivia/:id',
    name: 'trivia',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "trivia" */ '@/views/Trivia.vue'),
  },
  {
    path: '/personality-quiz/:id',
    name: 'personality-quiz',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "personality-quiz" */ '@/views/PersonalityQuiz.vue'),
  },
  {
    path: '/target/:id',
    name: 'target',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "target" */ '@/views/Target.vue'),
  },
  {
    path: '/verify',
    name: 'verify',
    component: () => import(/* webpackChunkName: "verify" */ '@/views/Verify.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'),
  },
  {
    path: '/locked',
    name: 'locked',
    component: () => import(/* webpackChunkName: "verify" */ '@/views/Locked.vue'),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/views/404NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    // Scroll to the top of the page on route navigation
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
